import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

const DialogTitle = (props) => {
  let lastColor = "";

  function handleClose() {
    props.closeForm();
  }

  function redBackground(e) {
    if (lastColor === "") {
      lastColor = e.target.style.color;
    }
    e.target.style.background = "red";
    e.target.style.color = "white";
  }

  function normalBackground(e) {
    e.target.style.background = "transparent";
    e.target.style.color = lastColor;
  }

  return (
    <Box
      sx={{
        padding: 0,
        cursor: "move",
        borderBottom: "2px solid grey",
        display: "grid",
        gridTemplateColumns: "4rem 1fr 4rem",
        gridTemplateRows: "4rem",
        minWidth: props.width,
        color: props.color,
        backgroundColor: props.backgroundColor,
      }}
      id={props.id || "draggable-dialog-title"}
    >
      <Box
        sx={{
          gridRow: "1",
          gridColumn: "2",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          fontSize: "3rem",
          fontWeight: "400",
          color: props.color,
        }}
      >
        {props.dialogTitle}
      </Box>
          <CloseIcon
            style={{
              fontSize: "3rem",
              margin: "8px 4px",
              gridColumn: "3",
              gridRow: "1",
              cursor: "default",
              color: props.color,
            }}
            onMouseEnter={redBackground}
            onMouseLeave={normalBackground}
            onClick={handleClose}
          />
    </Box>
  );
};

export default DialogTitle
