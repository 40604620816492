import React from "react";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoButton = (props) => {
  return (
    <Button
      id="infoButton"
      title="Info"
      style={{
        width: "7rem",
        height: "7rem",
        position: "relative",
        borderRadius: "6px",
        color: props.disabled ? "#2F2F2F" : "white",
        backgroundColor: props.disabled ? "#070A15" : "#243573",
        margin: "6px 10px",
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <InfoOutlinedIcon style={{ fontSize: "5rem" }} />
    </Button>
  );
};

export default InfoButton;
