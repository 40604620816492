import { store } from "../redux/store";
import { DateTime } from "luxon";
import { Box } from "@mui/material";

export function getSetting(key) {
  const state = store.getState();
  return state.time.Settings[key];
}

export function isOdd(num) {
  return num % 2 === 1;
}

export function twoDigits(value) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value.toString();
  }
}

export function threeDigits(value) {
  return value.toString();
}

export function timeDiff(timeString1, timeString2) {
  const minutes1 =
    parseInt(timeString1.substring(0, 2)) * 60 +
    parseInt(timeString1.substring(3, 5));
  const minutes2 =
    parseInt(timeString2.substring(0, 2)) * 60 +
    parseInt(timeString2.substring(3, 5));
  const minutes = Math.abs(minutes1 - minutes2);
  const tHours = Math.floor(minutes / 60);
  const tMinutes = minutes - tHours * 60;
  return twoDigits(tHours) + ":" + twoDigits(tMinutes);
}

export function timeDiffSec(timeString1, timeString2) {
  const seconds1 =
    parseInt(timeString1.substring(0, 2)) * 3600 +
    parseInt(timeString1.substring(3, 5)) * 60 +
    parseInt(timeString1.substring(6, 8));
  const seconds2 =
    parseInt(timeString2.substring(0, 2)) * 3600 +
    parseInt(timeString2.substring(3, 5)) * 60 +
    parseInt(timeString2.substring(6, 8));
  const seconds = Math.abs(seconds1 - seconds2);
  const tHours = Math.floor(seconds / 3600);
  const tMinutes = Math.floor((seconds - tHours * 3600) / 60);
  const tSeconds = seconds - tMinutes * 60 - tHours * 3600;
  return (
    twoDigits(tHours) + ":" + twoDigits(tMinutes) + ":" + twoDigits(tSeconds)
  );
}

export function SecondsFromTimeDiff(timeString1, timeString2) {
  if (timeString1.length > 0 && timeString2.length > 0) {
    const seconds1 =
      parseInt(timeString1.substring(0, 2)) * 3600 +
      parseInt(timeString1.substring(3, 5)) * 60 +
      parseInt(timeString1.substring(6, 8));
    const seconds2 =
      parseInt(timeString2.substring(0, 2)) * 3600 +
      parseInt(timeString2.substring(3, 5)) * 60 +
      parseInt(timeString2.substring(6, 8));
    // const seconds = Math.abs(seconds1 - seconds2);
    const seconds = seconds2 - seconds1;
    return seconds;
  }
  return 0;
}

export function secondsToTime(seconds, blink = false, noSeconds = false) {
  var separator = ":";
  const minus = seconds < 0 ? "-" : "";
  const absSeconds = Math.abs(seconds);
  var tHours = Math.floor(absSeconds / 3600);
  var tMinutes = Math.floor((absSeconds - tHours * 3600) / 60);
  var tSeconds = absSeconds - tMinutes * 60 - tHours * 3600;
  if (blink) {
    if (isOdd(tSeconds)) {
      separator = " ";
    } else {
      separator = ":";
    }
  }
  if (noSeconds) {
    return (
      minus +
      (tHours > 99 ? threeDigits(tHours) : twoDigits(tHours)) +
      separator +
      twoDigits(tMinutes)
    );
  } else {
    return (
      minus +
      twoDigits(tHours) +
      separator +
      twoDigits(tMinutes) +
      separator +
      twoDigits(tSeconds)
    );
  }
}

export function signedSecondsToTime(seconds) {
  var separator = ":";
  const minus = seconds < 0 ? "-" : "";
  const absSeconds = Math.abs(seconds);
  const tHours = Math.floor(absSeconds / 3600);
  const tMinutes = Math.floor((absSeconds - tHours * 3600) / 60);
  return minus + twoDigits(tHours) + separator + twoDigits(tMinutes);
}

export function timeToMinutes(timeString) {
  var minutes = parseInt(timeString.substring(3, 5));
  minutes += parseInt(timeString.substring(0, 2)) * 60;
  return minutes;
}

export function timeToSeconds(timeString) {
  var seconds = parseInt(timeString.substring(6, 8));
  seconds += parseInt(timeString.substring(3, 5)) * 60;
  seconds += parseInt(timeString.substring(0, 2)) * 3600;
  return seconds;
}

export function minutesToTime(minutes, blink = false) {
  var separator = ":";
  const minus = minutes < 0 ? "-" : "";
  const absMinutes = Math.abs(minutes === null ? 0 : minutes);
  var tHours = Math.floor(absMinutes / 60);
  var tMinutes = absMinutes % 60;
  var tSeconds = parseInt(DateTime.now().toFormat("s"));
  return (
    <Box>
      {minus + twoDigits(tHours)}
      <Box
        component="span"
        sx={{ color: blink && isOdd(tSeconds) ? "transparent" : "inherit" }}
      >
        {separator}
      </Box>
      {twoDigits(tMinutes)}
    </Box>
  );
}
