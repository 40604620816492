import {
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
  SET_TIME_SUCCESS,
  CLEAR_TIME,
  FETCH_TIMECLOCKS_SUCCESS,
  FETCH_TIMECLOCKS_FAILURE,
  FETCH_UNREGISTERED_TIMECLOCKS_SUCCESS,
  FETCH_TIMECLOCK_STATUS_SUCCESS,
  FETCH_TIMECLOCK_STATUS_FAILURE,
  SET_TIMECLOCK_ACTIVE,
  CLEAR_TIME_CLOCKS,
  SET_TAG,
  SET_RETURN_CODE,
  FETCH_PERSONS_SUCCESS,
  CLEAR_PERSONS,
  FETCH_PERSON_INFO_SUCCESS,
  FETCH_PERSON_TAGS_SUCCESS,
  CLEAR_PERSON_INFO,
  CHECK_TRANSPONDER_SUCCESS,
  ASSIGN_TRANSPONDER_SUCCESS,
  FETCH_TIME_FRAME,
  CLEAR_TIME_FRAME,
  READ_EXIT_REASONS,
  SET_EXIT_REASON,
} from "../actions/types";
import produce from "immer";

const initialState = {
  Settings: [],
  Time: [],
  TimeClocks: [],
  Status: [],
  Persons: [],
  Person: {},
  ExitReasons: [],
  currentTag: "",
  tagValid: null,
  currentPerson: "",
  exitReasonId: 0,
  timeFrame: {},
  error: "",
};

const timeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_SETTINGS_SUCCESS:
        draft.Settings = action.payload;
        break;
      case SET_TIME_SUCCESS:
        draft.Time = action.payload;
        draft.currentPerson = action.payload.id;
        draft.error = "";
        break;
      case CLEAR_TIME:
        draft.Time = [];
        break;
      case (FETCH_TIMECLOCKS_SUCCESS, FETCH_UNREGISTERED_TIMECLOCKS_SUCCESS):
        draft.TimeClocks = action.payload;
        draft.error = "";
        break;
      case FETCH_TIMECLOCK_STATUS_SUCCESS:
        draft.Status = action.payload;
        draft.error = "";
        break;
      case (FETCH_TIMECLOCKS_FAILURE,
      FETCH_TIMECLOCK_STATUS_FAILURE,
      FETCH_SETTINGS_FAILURE):
        draft.error = action.payload;
        break;
      case SET_TIMECLOCK_ACTIVE:
        key = draft.TimeClocks.findIndex(
          (DT) => parseInt(DT.id) === parseInt(action.payload.id)
        );
        draft.TimeClocks[key] = {
          ...draft.TimeClocks[key],
          active: action.payload.active,
        };
        break;
      case CLEAR_TIME_CLOCKS:
        draft.TimeClocks = [];
        break;
      case SET_TAG:
        draft.currentTag = action.payload;
        break;
      case SET_RETURN_CODE:
        draft.Time = { returnCode: action.payload };
        break;
      case FETCH_PERSONS_SUCCESS:
        draft.Persons = action.payload;
        break;
      case CLEAR_PERSONS:
        draft.Persons = [];
        break;
      case FETCH_PERSON_INFO_SUCCESS:
        draft.Person = action.payload;
        break;
      case FETCH_PERSON_TAGS_SUCCESS:
        draft.Person = action.payload;
        break;
      case CLEAR_PERSON_INFO:
        draft.Person = {};
        break;
      case CHECK_TRANSPONDER_SUCCESS:
        draft.tagValid = parseInt(action.payload.valid) === 1;
        break;
      case ASSIGN_TRANSPONDER_SUCCESS:
        action.payload.forEach((AP) => {
          key = draft.Persons.findIndex(
            (P) => parseInt(P.id) === parseInt(AP.id)
          );
          draft.Persons[key] = AP;
        });
        break;
      case FETCH_TIME_FRAME:
        draft.timeFrame = action.payload;
        break;
      case CLEAR_TIME_FRAME:
        draft.timeFrame = {};
        break;
      case READ_EXIT_REASONS:
        draft.ExitReasons = action.payload;
        break;
      case SET_EXIT_REASON:
        draft.exitReasonId = action.payload;
        break;
      default:
        return draft;
    }
  });

export default timeReducer;
