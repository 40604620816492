import React, { useEffect, useState } from "react";
import { store } from "../redux/store";
import { useTranslation } from "react-i18next";
import { getSetting } from "../components/Utilities";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseDialogButton from "../components/CloseDialogButton";
import MenuButton from "../components/MenuButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import RegisterDialog from "./RegisterDialog";
import UnregisterDialog from "./UnregisterDialog";
import AssignTagsDialog from "./AssignTagsDialog";

const AdminMenu = ({ closeAdminMenu }) => {
  const { t } = useTranslation();
  const displaySeconds =
    parseInt(getSetting("adminDisplaySeconds"));
  const [closeCounter, setCloseCounter] = useState(
    displaySeconds
  );
  const [idCode, setIdCode] = useState(localStorage.getItem("terminalIdCode"));
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);
  const [showUnregisterDialog, setShowUnregisterDialog] = useState(false);
  const [showAssignTagsDialog, setShowAssignTagsDialog] = useState(false);
  

  const handleClose = () => {
    closeAdminMenu();
  };

  const handleRegister = () => {
    setShowRegisterDialog(true);
  };

  const handleUnregister = () => {
    setShowUnregisterDialog(true);
  };

  const handleAssignTags = () => {
    store.dispatch({ type: "SET_TAG", payload: "" });
    setShowAssignTagsDialog(true);
  };

  const handleCloseUnregisterDialog = () => {
    setShowUnregisterDialog(false);
    setIdCode(localStorage.getItem("terminalIdCode"));
  };

  const handleCloseAssignTagsDialog = () => {
    setShowAssignTagsDialog(false);
  };

  const handleCloseRegisterDialog = () => {
    setShowRegisterDialog(false);
    setIdCode(localStorage.getItem("terminalIdCode"));
  };

  useEffect(() => {
    if (showUnregisterDialog || showAssignTagsDialog || showRegisterDialog) {
      setCloseCounter(displaySeconds);
    } else {
      if (closeCounter < 0) {
        closeAdminMenu();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCounter]);

  useEffect(() => {
    const closeRef = setInterval(() => {
      setCloseCounter((previous) => previous - 1);
    }, 1000);
    return () => {
      clearInterval(closeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        sx={{ p: 6 }}
        fullWidth
        maxWidth="md"
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="draggable-dialog-title"
        BackdropProps={{
          sx: { backgroundColor: "black" },
        }}
      >
        <DialogTitle
          className="dialogTitle"
          dialogTitle={t("Administration menu")}
          closeForm={handleClose}
          width="600px"
          color="white"
          backgroundColor="#243573"
        />
        <DialogContent sx={{ fontWeight: 200 }}>
          <Box>
            <MenuButton
              color="white"
              backgroundColor="#243573"
              text={t("Register terminal")}
              disabled={idCode !== null}
              onClick={handleRegister}
            />
          </Box>
          <Box>
            <MenuButton
              color="white"
              backgroundColor="#243573"
              text={t("Unregister terminal")}
              disabled={idCode === null}
              onClick={handleUnregister}
            />
          </Box>
          <Box>
            <MenuButton
              color="white"
              backgroundColor="#243573"
              text={t("Assign NFC-tags to users")}
              disabled={idCode === null}
              onClick={handleAssignTags}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "block", backgroundColor: "#BDBDBD" }}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <CloseDialogButton onClick={handleClose} />
          </Box>{" "}
          <Box sx={{ width: "100%", marginTop: "6px", marginRight: "6px" }}>
            <LinearProgress variant="determinate" value={closeCounter * 100 / displaySeconds} />
          </Box>
        </DialogActions>
      </Dialog>
      {showRegisterDialog && (
        <RegisterDialog closeRegisterDialog={handleCloseRegisterDialog} />
      )}
      {showUnregisterDialog && (
        <UnregisterDialog closeUnregisterDialog={handleCloseUnregisterDialog} />
      )}
      {showAssignTagsDialog && (
        <AssignTagsDialog closeAssignTagsDialog={handleCloseAssignTagsDialog} />
      )}
    </>
  );
};

export default AdminMenu;
