import React, { useEffect, useState } from "react";
import { store } from "../redux/store.js";
import { connect } from "react-redux";
import { fetchUnregisteredTimeClocks, setTimeClockActive } from "../redux/actions/time";
import { useTranslation } from "react-i18next";
import { getSetting } from "../components/Utilities";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SomuraSelectField from "../components/SomuraSelectField";
import OKButton from "../components/OKButton";
import CancelButton from "../components/CancelButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const mapStateToProps = (state) => {
  return {
    timeClocks: state.time.TimeClocks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTimeClocks: (values) => dispatch(fetchUnregisteredTimeClocks(values)),
    setTimeClockActive: (values) => dispatch(setTimeClockActive(values)),
  };
};

const RegisterDialog = ({
  timeClocks,
  fetchTimeClocks,
  setTimeClockActive,
  closeRegisterDialog,
}) => {
  const { t } = useTranslation();
  const [timeClockId, setTimeClockId] = useState(
    localStorage.getItem("terminalIdCode") || "0"
  );
  const displaySeconds = parseInt(getSetting("adminDisplaySeconds"));
  const [closeCounter, setCloseCounter] = useState(displaySeconds);

  var timeClockOptions = [];

  if (timeClocks.length > 0) {
    timeClockOptions = timeClocks.map((TC) => ({
      label: TC.location,
      value: TC.idCode,
    }));
  }
  timeClockOptions.push({
    label: "",
    value: "0",
  });

  if (timeClockId > 0) {
    timeClockOptions.push({
      label: timeClocks?.find((TC) => TC.idCode === timeClockId)?.location,
      value: timeClockId,
    });
  }

  const handleClose = () => {
    store.dispatch({ type: "CLEAR_TIME_CLOCKS" });
    closeRegisterDialog();
  };

  const handleSubmit = () => {
    if (timeClockId) {
      localStorage.setItem("terminalIdCode", timeClockId);
      localStorage.setItem(
        "terminalName",
        timeClocks?.find((TC) => TC.idCode === timeClockId)?.location
      );
      localStorage.setItem(
        "terminalLocationId",
        timeClocks?.find((TC) => TC.idCode === timeClockId)?.locationId
      );
      setTimeClockActive({
        id: timeClocks?.find((TC) => TC.idCode === timeClockId)?.id,
        isActive: true,
      });
    }
    handleClose();
  };

  const handleCodeChange = (event) => {
    const value = event.target.value;
    setTimeClockId(value);
  };

  useEffect(() => {
    fetchTimeClocks({locationId: localStorage.getItem("location")});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (closeCounter < 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCounter]);

  useEffect(() => {
    const closeRef = setInterval(() => {
      setCloseCounter((previous) => previous - 1);
    }, 1000);
    return () => {
      clearInterval(closeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      sx={{ p: 6 }}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle={t("Register terminal")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent sx={{ fontWeight: 200 }}>
        <Box>
          {timeClockOptions.length > 0 ? (
            <SomuraSelectField
              name="timeClockId"
              label={t("Location")}
              options={timeClockOptions}
              style={{ marginTop: "4px" }}
              value={timeClockId}
              onChange={handleCodeChange}
              variant="outlined"
            />
          ) : (
            <>
              <Box
                sx={{ textAlign: "center", fontSize: "1rem", fontWeight: 500 }}
              >
                {t("No available time clock codes found.")}
              </Box>
              <Box
                sx={{ textAlign: "center", fontSize: "1rem", fontWeight: 500 }}
              >
                {t("Please contact the administrator.")}
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "block", backgroundColor: "#BDBDBD" }}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <CancelButton onClick={handleClose} />
          <OKButton onClick={handleSubmit} />
        </Box>{" "}
        <Box sx={{ width: "100%", marginTop: "6px" }}>
          <LinearProgress
            variant="determinate"
            value={(closeCounter * 100) / displaySeconds}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDialog);
