import {
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
  SET_TIME_REQUEST,
  SET_TIME_SUCCESS,
  SET_TIME_FAILURE,
  CLEAR_TIME,
  FETCH_TIMECLOCKS_SUCCESS,
  FETCH_TIMECLOCKS_FAILURE,
  FETCH_UNREGISTERED_TIMECLOCKS_SUCCESS,
  FETCH_TIMECLOCK_STATUS_SUCCESS,
  FETCH_TIMECLOCK_STATUS_FAILURE,
  SET_TIMECLOCK_ACTIVE,
  SET_TAG,
  FETCH_PERSONS_SUCCESS,
  CLEAR_PERSONS,
  FETCH_PERSON_INFO_SUCCESS,
  FETCH_PERSON_TAGS_SUCCESS,
  CLEAR_PERSON_INFO,
  CHECK_TRANSPONDER_SUCCESS,
  ASSIGN_TRANSPONDER_SUCCESS,
  FETCH_TIME_FRAME,
  CLEAR_TIME_FRAME,
  READ_EXIT_REASONS,
  SET_EXIT_REASON,
} from "./types";
import {
  settingsRead,
  setTimeData,
  fetchTimeClockData,
  fetchUnregisteredTimeClockData,
  fetchTimeClockStatusData,
  setTimeClockActiveData,
  readPersonsData,
  readPersonInfoData,
  readPersonTagsData,
  checkTransponderData,
  assignTransponderData,
  checkTimeFrame,
  exitReasons
} from "../../api";

const fetchSettingsSuccess = (settings) => {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: settings,
  };
};
const fetchSettingsFailure = (settings) => {
  return {
    type: FETCH_SETTINGS_FAILURE,
    payload: settings,
  };
};

export const fetchSettings = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(settingsRead(values));
      await dispatch(fetchSettingsSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchSettingsFailure(err));
    }
  };
};

const setTimeRequest = () => {
  return {
    type: SET_TIME_REQUEST,
  };
};

const setTimeSuccess = (values) => {
  return {
    type: SET_TIME_SUCCESS,
    payload: values,
  };
};

const setTimeFailure = (err) => {
  return {
    type: SET_TIME_FAILURE,
    payload: err,
  };
};

export const setTime = (values) => {
  return async (dispatch) => {
    await dispatch(setTimeRequest());
    try {
      const JSONData = await dispatch(setTimeData(values));
      await dispatch(setTimeSuccess(JSONData));
    } catch (err) {
      await dispatch(setTimeFailure(err.message));
    }
  };
};

export const clearTime = () => {
  return {
    type: CLEAR_TIME,
  };
};

const fetchTimeClocksSuccess = (values) => {
  return {
    type: FETCH_TIMECLOCKS_SUCCESS,
    payload: values,
  };
};

const fetchTimeClocksFailure = (values) => {
  return {
    type: FETCH_TIMECLOCKS_FAILURE,
    payload: values,
  };
};

export const fetchTimeClocks = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(fetchTimeClockData(values));
      await dispatch(fetchTimeClocksSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

const fetchUnregisteredTimeClocksSuccess = (values) => {
  return {
    type: FETCH_UNREGISTERED_TIMECLOCKS_SUCCESS,
    payload: values,
  };
};

export const fetchUnregisteredTimeClocks = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(fetchUnregisteredTimeClockData(values));
      await dispatch(fetchUnregisteredTimeClocksSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

const fetchTimeClockStatusSuccess = (values) => {
  return {
    type: FETCH_TIMECLOCK_STATUS_SUCCESS,
    payload: values,
  };
};

const fetchTimeClockStatusFailure = (values) => {
  return {
    type: FETCH_TIMECLOCK_STATUS_FAILURE,
    payload: values,
  };
};

export const fetchTimeClockStatus = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(fetchTimeClockStatusData(values));
      await dispatch(fetchTimeClockStatusSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClockStatusFailure(err.message));
    }
  };
};

const setTimeClockActiveSuccess = (values) => {
  return {
    type: SET_TIMECLOCK_ACTIVE,
    payload: values,
  };
};

export const setTimeClockActive = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(setTimeClockActiveData(values));
      await dispatch(setTimeClockActiveSuccess());
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

export const setTag = (values) => {
  return {
    type: SET_TAG,
    payload: values,
  };
};

const fetchPersonsSuccess = (values) => {
  return {
    type: FETCH_PERSONS_SUCCESS,
    payload: values
  }
}

export const fetchPersons = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(readPersonsData(values));
      await dispatch(fetchPersonsSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

const fetchPersonInfoSuccess = (values) => {
  return {
    type: FETCH_PERSON_INFO_SUCCESS,
    payload: values
  }
}

export const fetchPersonInfo = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(readPersonInfoData(values));
      await dispatch(fetchPersonInfoSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

const fetchPersonTagsSuccess = (values) => {
  return {
    type: FETCH_PERSON_TAGS_SUCCESS,
    payload: values
  }
}

export const fetchPersonTags = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(readPersonTagsData(values));
      await dispatch(fetchPersonTagsSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

export const clearPersons = () => {
  return {
    type: CLEAR_PERSONS,
  };
};

export const clearPersonInfo = () => {
  return {
    type: CLEAR_PERSON_INFO,
  };
};

const assignTransponderSuccess = (values) => {
  return {
    type: ASSIGN_TRANSPONDER_SUCCESS,
    payload: values
  }
}

export const assignTransponder = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(assignTransponderData(values));
      await dispatch(assignTransponderSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

const checkTransponderSuccess = (values) => {
  return {
    type: CHECK_TRANSPONDER_SUCCESS,
    payload: values
  }
}

export const checkTransponder = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(checkTransponderData(values));
      await dispatch(checkTransponderSuccess(JSONData));
    } catch (err) {
      await dispatch(fetchTimeClocksFailure(err.message));
    }
  };
};

const fetchTimeFrameData = (values) => {
  return {
    type: FETCH_TIME_FRAME,
    payload: values
  }
}

export const fetchTimeFrame = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(checkTimeFrame(values));
      await dispatch(fetchTimeFrameData(JSONData));
    } catch (err) {
      await dispatch(setTimeFailure(err.message));
    }
  }
}

export const clearTimeFrame = () => {
  return {
    type: CLEAR_TIME_FRAME
  }
}

const fetchExitReasonsSuccess = (offDayTypes) => {
  return {
    type: READ_EXIT_REASONS,
    payload: offDayTypes,
  };
};

export const fetchExitReasons = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(exitReasons(values));
      await dispatch(fetchExitReasonsSuccess(JSONData));
    } catch (err) {
      await dispatch(setTimeFailure(err.message));
    }
  };
};

export const setExitReason = (values) => {
  return {
    type: SET_EXIT_REASON,
    payload: values
  }
}