import { configureStore } from "@reduxjs/toolkit";
import timeReducer from "./reducer/time";

export const store = configureStore({
  reducer: {
    // ? Add the authReducer to the reducer object
    time: timeReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}),
});

