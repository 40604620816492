var baseUrl;
console.log("Host: ", window.location.host);
if (
  window.location.host.includes("m-t-g") ||
  window.location.host.includes("mtg")
) {
  baseUrl = "//somura.m-t-g.de/api/";
}
if (window.location.host.includes("netitwork")) {
  baseUrl = "//somura.netitwork.net/api/";
}
if (window.location.hostname === "localhost") {
  baseUrl = "https://niw-wrank-dev-01.netitwork.net/api/";
  // baseUrl = "https://somura.m-t-g.de/api/";
}


function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

async function post(path, data) {
  return fetch(baseUrl + path, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then((response) => response.json());
}

export function settingsRead(values) {
  return async () => {
    return await post("globalsettings/read_component.php", values);
  };
}

export function setTimeData(values) {
  return async () => {
    return await post("time/setTimeClock.php", values);
  };
}

export function fetchTimeClockData(values) {
  return async () => {
    return await post("timeclock/read.php", values);
  };
}

export function fetchUnregisteredTimeClockData(values) {
  return async () => {
    return await post("timeclock/read_unregistered.php", values);
  };
}

export function fetchTimeClockStatusData(values) {
  return async () => {
    return await post("timeclock/getStatus.php", values);
  };
}

export function setTimeClockActiveData(values) {
  return async () => {
    return await post("timeclock/setActive.php", values);
  };
}

export function readPersonsData(values) {
  return async () => {
    return await post("time/read_persons.php", values);
  };
}

export function readPersonInfoData(values) {
  return async () => {
    return await post("time/read_person_info.php", values);
  };
}

export function readPersonTagsData(values) {
  return async () => {
    return await post("time/read_person_tags.php", values);
  };
}

export function checkTransponderData(values) {
  return async () => {
    return await post("time/check_tag.php", values);
  };
}

export function assignTransponderData(values) {
  return async () => {
    return await post("time/assign_transponder.php", values);
  };
}

export function checkTimeFrame(values) {
  return async () => {
    return await post("time/checkDailyTimePlan.php", values);
  };
}

export function exitReasons(values) {
  return async () => {
    return await post("exitreason/read.php", values);
  };
}
