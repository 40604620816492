import React from "react";
import Button from "@mui/material/Button";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTranslation } from "react-i18next";

const AssignButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      style={{ width: "18rem", fontSize: "2rem" }}
      type="submit"
      variant="contained"
      startIcon={
        <CheckCircleOutlineOutlinedIcon style={{ fontSize: "2rem" }} />
      }
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {t("Save")}
    </Button>
  );
};

export default AssignButton;
