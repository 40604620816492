import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { store } from "../redux/store";
import { DateTime } from "luxon";
import theme from "../Theme";
import { fetchPersonInfo, clearPersonInfo } from "../redux/actions/time";
import { useTranslation } from "react-i18next";
import {
  getSetting,
  minutesToTime,
} from "../components/Utilities";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import OKButton from "../components/OKButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const mapStateToProps = (state) => {
  return {
    transponder: state.time.currentTag,
    personInfo: state.time.Person,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPersonInfo: (values) => dispatch(fetchPersonInfo(values)),
    clearPersonInfo: () => dispatch(clearPersonInfo()),
  };
};

const PersonInfo = ({
  transponder,
  personInfo,
  fetchPersonInfo,
  clearPersonInfo,
  closePersonInfo,
}) => {
  const { t } = useTranslation();
  const displaySeconds = parseInt(getSetting("infoDisplaySeconds"));
  const [closeCounter, setCloseCounter] = useState(displaySeconds);
  const infoRef = useRef();
  const d_time = useRef();
  const w_time = useRef();
  const m_time = useRef();
  const y_time = useRef();

  const setDisplayTime = () => {
    const diff =
     personInfo.worktimeRunning === "1"
        ? parseInt(DateTime.now().toFormat("H")) * 60 +
          parseInt(DateTime.now().toFormat("M")) -
          personInfo.startMinutes
        : 0;
    d_time.current = personInfo.d_minutes + diff;
    w_time.current = personInfo.w_minutes + diff;
    m_time.current = personInfo.m_minutes + diff;
    y_time.current = personInfo.y_minutes + diff;
  };

  const handleClose = () => {
    clearPersonInfo();
    closePersonInfo();
  };

  useEffect(() => {
    if (transponder !== "") {
      fetchPersonInfo({ transponder: transponder });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transponder]);

  useEffect(() => {
    if (personInfo) {
      if (personInfo.lastName === null) {
        store.dispatch({ type: "SET_RETURN_CODE", payload: "Not recognized" });
        handleClose();
      } else {
        d_time.current = personInfo.d_minutes;
        w_time.current = personInfo.w_minutes;
        m_time.current = personInfo.m_minutes;
        y_time.current = personInfo.y_minutes;
        if (personInfo.worktimeRunning === "1") {
          infoRef.current = setInterval(() => {
            setDisplayTime();
          }, 1000);
          return () => {
            clearInterval(infoRef.current);
          };
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personInfo]);

  useEffect(() => {
    if (closeCounter < 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCounter]);

  useEffect(() => {
    const closeRef = setInterval(() => {
      setCloseCounter((previous) => previous - 1);
    }, 1000);
    return () => {
      clearInterval(closeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      sx={{ p: 6 }}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth={"md"}
      fullWidth
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        dialogTitle={
          personInfo.lastName +
          ", " +
          personInfo.firstName +
          " - " +
          personInfo.personNumber
        }
        closeForm={handleClose}
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent sx={{ fontWeight: 200 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "2rem 1fr",
          }}
        >
          <Box
            sx={{
              gridColumn: 1,
              gridRow: 1,
              fontSize: "1.8rem",
              lineHeight: "1.5rem",
              color: theme.palette.somura,
              fontWeight: 600,
            }}
          >
            {t("Working times").toUpperCase()}
          </Box>
          <Box
            sx={{
              fontSize: "1.5rem",
              fontWeight: 500,
              gridColumn: 1,
              gridRow: 2,
              display: "grid",
              gridTemplateColumns: "1fr 9rem 4rem",
              gridTemplateRows: "2rem 2rem 2rem 2rem",
            }}
          >
            <Box sx={{ gridRow: 1, gridColumn: 1 }}>{t("Today")}</Box>
            <Box sx={{ gridRow: 1, gridColumn: 2, textAlign: "right" }}>
              {d_time.current > 0
                ? minutesToTime(
                    d_time.current,
                    personInfo.worktimeRunning === "1"
                  )
                : "-"}
            </Box>
            <Box sx={{ gridRow: 2, gridColumn: 1 }}>{t("This week")}</Box>
            <Box sx={{ gridRow: 2, gridColumn: 2, textAlign: "right" }}>
              {w_time.current > 0
                ? minutesToTime(
                    w_time.current,
                    personInfo.worktimeRunning === "1"
                  )
                : "-"}
            </Box>
            <Box sx={{ gridRow: 3, gridColumn: 1 }}>{t("This month")}</Box>
            <Box sx={{ gridRow: 3, gridColumn: 2, textAlign: "right" }}>
              {m_time.current > 0
                ? minutesToTime(
                    m_time.current,
                    personInfo.worktimeRunning === "1"
                  )
                : "-"}
            </Box>
            <Box sx={{ gridRow: 4, gridColumn: 1 }}>{t("This year")}</Box>
            <Box sx={{ gridRow: 4, gridColumn: 2, textAlign: "right" }}>
              {y_time.current > 0
                ? minutesToTime(
                    y_time.current,
                    personInfo.worktimeRunning === "1"
                  )
                : "-"}
            </Box>
          </Box>
          <Box
            sx={{
              gridColumn: 2,
              gridRow: 1,
              fontSize: "1.8rem",
              lineHeight: "1.5rem",
              color: theme.palette.somura,
              fontWeight: 600,
            }}
          >
            {t("Vacation").toUpperCase()}
          </Box>
          <Box
            sx={{
              fontSize: "1.5rem",
              fontWeight: 500,
              gridColumn: 2,
              gridRow: 2,
              display: "grid",
              gridTemplateColumns: "1fr 5rem 4rem",
              gridTemplateRows: "2rem 2rem 2rem 2rem",
            }}
          >
            <Box sx={{ gridRow: 1, gridColumn: 1 }}>{t("Taken")}</Box>
            <Box sx={{ gridRow: 1, gridColumn: 2, textAlign: "right" }}>
              {personInfo.vacation_old}
            </Box>
            <Box sx={{ gridRow: 2, gridColumn: 1 }}>{t("Planned")}</Box>
            <Box sx={{ gridRow: 2, gridColumn: 2, textAlign: "right" }}>
              {personInfo.vacation_planed}
            </Box>
            <Box sx={{ gridRow: 3, gridColumn: 1 }}>
              {t("Requested / Approved")}
            </Box>
            <Box sx={{ gridRow: 3, gridColumn: 2, textAlign: "right" }}>
              {personInfo.vacation_approved_requested}
            </Box>
            <Box sx={{ gridRow: 4, gridColumn: 1 }}>{t("Available")}</Box>
            <Box sx={{ gridRow: 4, gridColumn: 2, textAlign: "right" }}>
              {personInfo.entitlement_new}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "block", backgroundColor: "#BDBDBD" }}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <OKButton onClick={handleClose} />
        </Box>
        <Box sx={{ width: "100%", marginTop: "6px" }}>
          <LinearProgress
            variant="determinate"
            value={(closeCounter * 100) / displaySeconds}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonInfo);
