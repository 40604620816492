export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const SET_TIME_REQUEST = "SET_TIME_REQUEST";
export const SET_TIME_SUCCESS = "SET_TIME_SUCCESS";
export const SET_TIME_FAILURE = "SET_TIME_FAILURE";
export const CLEAR_TIME = "CLEAR_TIME";
export const FETCH_TIMECLOCKS_SUCCESS = "FETCH_TIMECLOCKS_SUCCESS";
export const FETCH_TIMECLOCKS_FAILURE = "FETCH_TIMECLOCKS_FAILURE";
export const FETCH_UNREGISTERED_TIMECLOCKS_SUCCESS = "FETCH_UNREGISTERED_TIMECLOCKS_SUCCESS";
export const FETCH_TIMECLOCK_STATUS_SUCCESS = "FETCH_TIMECLOCK_STATUS_SUCCESS";
export const FETCH_TIMECLOCK_STATUS_FAILURE = "FETCH_TIMECLOCK_STATUS_FAILURE";
export const SET_TIMECLOCK_ACTIVE = "SET_TIMECLOCK_ACTIVE";
export const CLEAR_TIME_CLOCKS = "CLEAR_TIME_CLOCKS";
export const SET_TAG = "SET_TAG"
export const SET_RETURN_CODE = "SET_RETURN_CODE"
export const FETCH_PERSONS_REQUEST = "FETCH_PERSONS_REQUEST";
export const FETCH_PERSONS_SUCCESS = "FETCH_PERSONS_SUCCESS";
export const CLEAR_PERSONS = "CLEAR_PERSONS";
export const FETCH_PERSON_INFO_SUCCESS = "FETCH_PERSON_INFO_SUCCESS";
export const FETCH_PERSON_TAGS_SUCCESS = "FETCH_PERSON_TAGS_SUCCESS";
export const CLEAR_PERSON_INFO = "CLEAR_PERSON_INFO";
export const FETCH_TIME_FRAME = "FETCH_TIME_FRAME";
export const CLEAR_TIME_FRAME = "CLEAR_TIME_FRAME";
export const CHECK_TRANSPONDER_SUCCESS = "CHECK_TRANSPONDER_SUCCESS";
export const ASSIGN_TRANSPONDER_SUCCESS = "ASSIGN_TRANSPONDER_SUCCESS";
export const READ_EXIT_REASONS = "READ_EXIT_REASONS";
export const SET_EXIT_REASON = "SET_EXIT_REASON";