import React from "react";
import Button from "@mui/material/Button";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";

const BreakButton = (props) => {
  return (
    <Button
      title="Break"
      style={{
        width: "7rem",
        height: "7rem",
        position: "relative",
        borderRadius: "6px",
        color: props.disabled ? "#2F2F2F" : "white",
        backgroundColor: props.disabled ? "#2e1a00" : "#fb8c00",
        margin: "6px 10px",
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <LocalCafeOutlinedIcon style={{ fontSize: "5rem" }} />
    </Button>
  );
};

export default BreakButton;
