import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";

const SomuraSelectField = (props) => {
  const items = props.options.map((O, index) => (
    <MenuItem sx={{ fontSize: "2rem" }} key={index} value={O.value}>
      {O.label}
    </MenuItem>
  ));
  return (
    <>
      <Box sx={{fontSize: "2rem", fontWeight: 500}}>{props.label}</Box>
      <Select
        SelectDisplayProps={{
          sx: {
            height: "5rem",
            width: "32rem",
            fontSize: "3rem",
          },
        }}
        {...props}
      >
        {items}
      </Select>
    </>
  );
};

export default SomuraSelectField;
