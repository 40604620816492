import React from "react";
import Button from "@mui/material/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const DeleteTagButton = (props) => {
  return (
    <Button
      style={{
        width: "4rem",
        height: "4rem",
        fontSize: "3rem",
        borderRadius: "3rem",
        color: props.disabled ? "lightgrey" : "white",
        backgroundColor: props.disabled ? "grey" : "red",
        boxShadow: props.disabled
          ? "none"
          : "2px 2px 4px 2px rgba(0, 0, 0, 0.4)",
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <DeleteOutlinedIcon style={{ fontSize: "3rem" }} />
    </Button>
  );
};

export default DeleteTagButton;
