import React from "react";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";

const NoButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      startIcon={<LogoutIcon style={{ fontSize: "2rem" }} />}
      color="secondary"
      onClick={props.onClick}
      style={{
        marginLeft: "1rem",
        width: "18rem",
        fontSize: "2rem",
        color: "white",
        backgroundColor: "#ba000d",
      }}
    >
      {t("No")}
    </Button>
  );
};

export default NoButton;
