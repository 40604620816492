import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setExitReason } from "../redux/actions/time";
import { useTranslation } from "react-i18next";
import { getSetting } from "../components/Utilities";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseDialogButton from "../components/CloseDialogButton";
import ExitReasonButton from "../components/ExitReasonButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const mapStateToProps = (state) => {
  return {
    exitReasons: state.time.ExitReasons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setExitReason: (values) => dispatch(setExitReason(values)),
  };
};

const ExitDialog = ({ closeExitDialog, exitReasons, setExitReason }) => {
  const { t } = useTranslation();
  const displaySeconds = parseInt(getSetting("adminDisplaySeconds"));
  const closeCounter = useRef(displaySeconds);

  const ReasonsList = () => {
    const items = exitReasons.map((ER, index) => (
      <Box
        key={index}
        sx={{
          gridColumn:
            index % 2 === 0
              ? index === exitReasons.length - 1
                ? "2 / 4 "
                : "1 / 3"
              : "3 / 5",
          padding: "0 4px",
        }}
      >
        <ExitReasonButton
          color={ER.fontColor}
          backgroundColor={ER.backColor}
          text={ER.name}
          onClick={() => {
            handleExitClick(ER.id);
          }}
        />
      </Box>
    ));
    return items;
  };

  const handleClose = () => {
    setExitReason(0);
    closeExitDialog();
  };

  const handleExitClick = (id) => {
    console.log(id);
    setExitReason(id);
    closeExitDialog();
  };

  useEffect(() => {
    if (closeCounter.current < 0) {
      setExitReason(0);
      closeExitDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCounter.current]);

  useEffect(() => {
    const closeRef = setInterval(() => {
      closeCounter.current--;
    }, 1000);
    return () => {
      clearInterval(closeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        sx={{ p: 6 }}
        fullWidth
        maxWidth="lg"
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="draggable-dialog-title"
        BackdropProps={{
          sx: { backgroundColor: "black" },
        }}
      >
        <DialogTitle
          className="dialogTitle"
          dialogTitle={t("Select a reason")}
          closeForm={handleClose}
          width="600px"
          color="white"
          backgroundColor="#e72b2d"
        />
        <DialogContent sx={{ fontWeight: 200 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              minHeight: "24rem",
              overflowY: "auto",
            }}
          >
            <ReasonsList />
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "block", backgroundColor: "#BDBDBD" }}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <CloseDialogButton onClick={handleClose} />
          </Box>{" "}
          <Box sx={{ width: "100%", marginTop: "6px", marginRight: "6px" }}>
            <LinearProgress
              variant="determinate"
              value={(closeCounter.current * 100) / displaySeconds}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExitDialog);
