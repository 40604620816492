import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { store } from "../redux/store";
import {
  fetchPersons,
  fetchPersonTags,
  clearPersons,
  assignTransponder,
} from "../redux/actions/time";
import { getSetting } from "../components/Utilities";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SomuraSelectField from "../components/SomuraSelectField";
import AssignButton from "../components/AssignButton";
import CancelAssignButton from "../components/CancelAssignButton";
import CloseDialogButton from "../components/CancelButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import Looks3OutlinedIcon from "@mui/icons-material/Looks3Outlined";
import ReadTagButton from "../components/ReadTagButton";
import StopTagButton from "../components/StopTagButton";
import DeleteTagButton from "../components/DeleteTagButton";

const mapStateToProps = (state) => {
  return {
    currentTag: state.time.currentTag,
    persons: state.time.Persons,
    tags: state.time.Person,
    locationId: state.time.Status.locationId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPersons: (values) => dispatch(fetchPersons(values)),
    clearPersons: () => dispatch(clearPersons()),
    fetchPersonTags: (values) => dispatch(fetchPersonTags(values)),
    assignTransponder: (values) => dispatch(assignTransponder(values)),
  };
};

const AssignTagsDialog = ({
  currentTag,
  persons,
  tags,
  locationId,
  fetchPersons,
  clearPersons,
  fetchPersonTags,
  assignTransponder,
  closeAssignTagsDialog,
}) => {
  const { t } = useTranslation();
  const adminCode1 = getSetting("adminTransponder1");
  const adminCode2 = getSetting("adminTransponder2");
  const adminCode3 = getSetting("adminTransponder3");
  const displaySeconds = parseInt(getSetting("adminDisplaySeconds"));
  const [closeCounter, setCloseCounter] = useState(displaySeconds);
  const [personId, setPersonId] = useState(0);
  const [workflowState, setWorkflowState] = useState(1);
  const [readTag, setReadTag] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [currentTags, setCurrentTags] = useState();

  var personOptions = [];

  if (persons.length > 0) {
    personOptions = persons.map((P) => ({
      label: P.lastName + ", " + P.firstName,
      value: P.id,
    }));
  }
  personOptions.push({
    label: "",
    value: "0",
  });

  const showErrorMessage = () => {
    setShowMessage(true);
    setTimeout(() => {
      handleReset();
    }, 4000);
  };

  const showHintMessage = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      setMessage("");
    }, 4000);
  };

  const handleSubmit = () => {
    assignTransponder({
      ...currentTags,
      assignTo: personId,
    });
    setError(false);
    setMessage(t("Tags successfully assigned."));
    showErrorMessage();
    setCloseCounter(displaySeconds);
  };

  const handleReset = () => {
    store.dispatch({ type: "SET_TAG", payload: "" });
    setShowMessage(false);
    setMessage("");
    setPersonId(0);
    setWorkflowState(1);
    setCloseCounter(displaySeconds);
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_TAG", payload: "" });
    clearPersons();
    closeAssignTagsDialog();
  };

  const handlePersonChange = (event) => {
    const value = event.target.value;
    if (value > 0) {
      setPersonId(value);
      fetchPersonTags({ userId: value });
      setReadTag(0);
      setWorkflowState(2);
    }
    setCloseCounter(displaySeconds);
  };

  const handleReadTag1 = () => {
    setReadTag(1);
  };
  const handleReadTag2 = () => {
    setReadTag(2);
  };
  const stopRead = () => {
    setReadTag(0);
  };
  const handleReadTag3 = () => {
    setReadTag(3);
  };
  const handleRemoveTag1 = () => {
    setCurrentTags({ ...currentTags, transponder1: "" });
    setReadTag(0);
  };
  const handleRemoveTag2 = () => {
    setCurrentTags({ ...currentTags, transponder2: "" });
    setReadTag(0);
  };
  const handleRemoveTag3 = () => {
    setCurrentTags({ ...currentTags, transponder3: "" });
    setReadTag(0);
  };

  useEffect(() => {
    fetchPersons({locationId: locationId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tags) {
      setCurrentTags({
        transponder1: tags.transponder1,
        transponder2: tags.transponder2,
        transponder3: tags.transponder3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (currentTag !== "" && readTag !== 0) {
      if (
        currentTag === adminCode1 ||
        currentTag === adminCode2 ||
        currentTag === adminCode3
      ) {
        setError(true);
        setMessage(t("Admin tag may not be reassigned."));
        showErrorMessage();
      } else {
        // Check if tag is already assigned
        const assigned = persons.findIndex(
          (P) =>
            P.transponder1 === currentTag ||
            P.transponder2 === currentTag ||
            P.transponder3 === currentTag
        );
        if (assigned > -1) {
          setError(true);
          setMessage(
            t("Tag already assigned to ") +
              persons[assigned].lastName +
              ", " +
              persons[assigned].firstName
          );
          setPersonId(persons[assigned].id);
          store.dispatch({ type: "SET_TAG", payload: "" });
          showHintMessage();
          setReadTag(0);
          setWorkflowState(2);
        } else {
          switch (readTag) {
            case 1:
              setCurrentTags({ ...currentTags, transponder1: currentTag });
              break;
            case 2:
              setCurrentTags({ ...currentTags, transponder2: currentTag });
              break;
            case 3:
              setCurrentTags({ ...currentTags, transponder3: currentTag });
              break;
            default:
          }
          setReadTag(0);
          setWorkflowState(3);
        }
      }
    }
    setCloseCounter(displaySeconds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTag]);

  useEffect(() => {
    if (closeCounter < 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCounter]);

  useEffect(() => {
    const closeRef = setInterval(() => {
      setCloseCounter((previous) => previous - 1);
    }, 1000);
    return () => {
      clearInterval(closeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      sx={{ p: 6 }}
      PaperProps={{
        sx: {
          maxHeight: "none",
        },
      }}
      maxWidth="lg"
      fullWidth
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle={t("Assign NFC-tags to users")}
        closeForm={handleClose}
        maxWidth="850px"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent sx={{ fontWeight: 500 }}>
        <Box
          sx={{
            fontSize: "2.5rem",
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "30rem 2rem 1fr 4.5rem 4.5rem",
            gridTemplateRows: "repeat(5, 5rem) 3rem",
          }}
        >
          {workflowState >= 1 && (
            <>
              <Box
                sx={{
                  gridRow: 1,
                  gridColumn: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("1. Select user")}
              </Box>
              <Box
                sx={{
                  gridRow: 1,
                  gridColumn: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EastOutlinedIcon />
              </Box>
              <Box
                sx={{
                  gridRow: 1,
                  gridColumn: "3 / 6",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SomuraSelectField
                  name="person"
                  options={personOptions}
                  style={{ marginTop: "4px" }}
                  value={personId}
                  onChange={handlePersonChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Box>
            </>
          )}
          {workflowState >= 2 && (
            <>
              <Box
                sx={{
                  gridRow: 2,
                  gridColumn: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("2. Assign NFC tags")}
              </Box>
              <Box
                sx={{
                  gridRow: 2,
                  gridColumn: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {parseInt(readTag) === 1 ? (
                  <EastOutlinedIcon />
                ) : (
                  <LooksOneOutlinedIcon
                    sx={{ fontSize: "3rem", marginTop: "6px" }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  gridRow: 2,
                  gridColumn: 3,
                  display: "flex",
                  alignItems: "center",
                  textAlign: "right",
                  marginLeft: "1rem",
                }}
              >
                {parseInt(readTag) === 1 ? (
                  <Box
                    sx={{
                      gridRow: 2,
                      gridColumn: 3,
                      width: "6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "8px",
                        gridRow: 2,
                        gridColumn: 3,
                        backgroundColor: "#243573",
                        "@keyframes MoveLeftRight": {
                          "0%, 100%": {
                            transform: "translateX(0)",
                          },
                          "50%": {
                            transform: "translateX(20rem)",
                          },
                        },
                        animation: "MoveLeftRight 4s linear infinite",
                      }}
                    ></Box>
                  </Box>
                ) : currentTags.transponder1 === tags.transponder1 ||
                  currentTags.transponder1 === "" ? (
                  <Box
                    sx={{
                      fontStyle: "italic",
                      color: "somura",
                      fontSize: "2rem",
                      fontWeight: 400,
                    }}
                  >
                    {currentTags.transponder1 === ""
                      ? t("No Tag assigned")
                      : currentTags.transponder1}
                  </Box>
                ) : (
                  <Box>{currentTags.transponder1}</Box>
                )}
              </Box>
              <Box sx={{ gridRow: 2, gridColumn: 4 }}>
                {parseInt(readTag) === 1 ? (
                  <StopTagButton onClick={stopRead} />
                ) : (
                  <ReadTagButton
                    disabled={currentTags.transponder1 !== "" || parseInt(readTag) !== 0}
                    onClick={handleReadTag1}
                  />
                )}
              </Box>

              <Box sx={{ gridRow: 2, gridColumn: 5 }}>
                <DeleteTagButton
                  disabled={currentTags.transponder1 === ""}
                  onClick={handleRemoveTag1}
                />
              </Box>
              <Box
                sx={{
                  gridRow: 3,
                  gridColumn: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {parseInt(readTag) === 2 ? (
                  <EastOutlinedIcon />
                ) : (
                  <LooksTwoOutlinedIcon
                    sx={{ fontSize: "3rem", marginTop: "6px" }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  gridRow: 3,
                  gridColumn: 3,
                  display: "flex",
                  alignItems: "center",
                  textAlign: "right",
                  marginLeft: "1rem",
                }}
              >
                {parseInt(readTag) === 2 ? (
                  <Box
                    sx={{
                      gridRow: 3,
                      gridColumn: 3,
                      width: "6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "8px",
                        gridRow: 3,
                        gridColumn: 3,
                        backgroundColor: "#243573",
                        "@keyframes MoveLeftRight": {
                          "0%, 100%": {
                            transform: "translateX(0)",
                          },
                          "50%": {
                            transform: "translateX(20rem)",
                          },
                        },
                        animation: "MoveLeftRight 4s linear infinite",
                      }}
                    ></Box>
                  </Box>
                ) : currentTags.transponder2 === tags.transponder2 ||
                  currentTags.transponder2 === "" ? (
                  <Box
                    sx={{
                      fontStyle: "italic",
                      color: "somura",
                      fontSize: "2rem",
                      fontWeight: 400,
                    }}
                  >
                    {currentTags.transponder2 === ""
                      ? t("No Tag assigned")
                      : currentTags.transponder2}
                  </Box>
                ) : (
                  <Box>{currentTags.transponder2}</Box>
                )}
              </Box>

              <Box sx={{ gridRow: 3, gridColumn: 4 }}>
                {parseInt(readTag) === 2 ? (
                  <StopTagButton onClick={stopRead} />
                ) : (
                  <ReadTagButton
                    disabled={currentTags.transponder2 !== "" || readTag !== 0}
                    onClick={handleReadTag2}
                  />
                )}
              </Box>

              <Box sx={{ gridRow: 3, gridColumn: 5 }}>
                <DeleteTagButton
                  disabled={currentTags.transponder2 === ""}
                  onClick={handleRemoveTag2}
                />
              </Box>
              <Box
                sx={{
                  gridRow: 4,
                  gridColumn: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {parseInt(readTag) === 3 ? (
                  <EastOutlinedIcon />
                ) : (
                  <Looks3OutlinedIcon
                    sx={{ fontSize: "3rem", marginTop: "6px" }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  gridRow: 4,
                  gridColumn: 3,
                  display: "flex",
                  alignItems: "center",
                  textAlign: "right",
                  marginLeft: "1rem",
                }}
              >
                {parseInt(readTag) === 3 ? (
                  <Box
                    sx={{
                      gridRow: 4,
                      gridColumn: 3,
                      width: "6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "8px",
                        gridRow: 4,
                        gridColumn: 3,
                        backgroundColor: "#243573",
                        "@keyframes MoveLeftRight": {
                          "0%, 100%": {
                            transform: "translateX(0)",
                          },
                          "50%": {
                            transform: "translateX(20rem)",
                          },
                        },
                        animation: "MoveLeftRight 4s linear infinite",
                      }}
                    ></Box>
                  </Box>
                ) : currentTags.transponder3 === tags.transponder3 ||
                  currentTags.transponder3 === "" ? (
                  <Box
                    sx={{
                      fontStyle: "italic",
                      color: "somura",
                      fontSize: "2rem",
                      fontWeight: 400,
                    }}
                  >
                    {currentTags.transponder3 === ""
                      ? t("No Tag assigned")
                      : currentTags.transponder3}
                  </Box>
                ) : (
                  <Box>{currentTags.transponder3}</Box>
                )}
              </Box>

              <Box sx={{ gridRow: 4, gridColumn: 4 }}>
                {parseInt(readTag) === 3 ? (
                  <StopTagButton onClick={stopRead} />
                ) : (
                  <ReadTagButton
                    disabled={currentTags.transponder3 !== "" || parseInt(readTag) !== 0}
                    onClick={handleReadTag3}
                  />
                )}
              </Box>

              <Box sx={{ gridRow: 4, gridColumn: 5 }}>
                <DeleteTagButton
                  disabled={currentTags.transponder3 === ""}
                  onClick={handleRemoveTag3}
                />
              </Box>

              <Box
                sx={{
                  gridRow: 5,
                  gridColumn: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("3. Confirm")}
              </Box>
              <Box
                sx={{
                  gridRow: 5,
                  gridColumn: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EastOutlinedIcon />
              </Box>

              <Box
                sx={{
                  gridRow: 5,
                  gridColumn: "3 / 6",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <AssignButton onClick={handleSubmit} />
                <CancelAssignButton onClick={handleReset} />
              </Box>
            </>
          )}
          {showMessage && (
            <Box
              sx={{
                fontSize: "2rem",
                gridRow: 6,
                gridColumn: "1 / 6",
                color: error ? "red.main" : "green",
              }}
            >
              {message}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "block", backgroundColor: "#BDBDBD" }}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <CloseDialogButton onClick={handleClose} />
        </Box>{" "}
        <Box sx={{ width: "100%", marginTop: "6px" }}>
          <LinearProgress
            variant="determinate"
            value={(closeCounter * 100) / displaySeconds}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignTagsDialog);
