import React, { useEffect} from "react";
import { connect } from "react-redux";
import { fetchSettings } from "./redux/actions/time";
import TimeClock from "./modules/TimeClock";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: () => dispatch(fetchSettings({component:"timeclocks"})),
  };
};

function App({ fetchSettings }) {
  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TimeClock />;
}

export default connect(null, mapDispatchToProps)(App);
