import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import { setTime } from "../redux/actions/time.js";
import { useTranslation } from "react-i18next";
import { getSetting } from "../components/Utilities.js";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../components/DialogTitle.js";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import YesButton from "../components/YesButton.js";
import NoButton from "../components/NoButton.js";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const mapStateToProps = (state) => {
  return {
    transponder: state.time.currentTag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTime: (values) => dispatch(setTime(values)),
  };
};

const StartBreakDialog = ({ transponder, closeStartBreak, setTime }) => {
  const { t } = useTranslation();
  const displaySeconds = parseInt(getSetting("askBreakDisplaySeconds"));
  const [closeCounter, setCloseCounter] = useState(displaySeconds);
  const breakMode = useRef("0");

  const handleClose = () => {
    const transmit = {
      transponder: transponder,
      date: DateTime.now().toSQLDate(),
      time: DateTime.now().toFormat("HH:mm:ss"),
      minutes:
        parseInt(DateTime.now().toFormat("HH")) * 60 +
        parseInt(DateTime.now().toFormat("mm")),
      isBreak: breakMode.current,
      terminalId: localStorage.getItem("terminalIdCode"),
    };
    setTime(transmit);
    closeStartBreak();
  };

  const handleBreak = () => {
    breakMode.current = "1"
    handleClose();
  };

  const handleExit = () => {
    breakMode.current = "0";
    handleClose();
  };

  useEffect(() => {
    if (closeCounter < 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCounter]);

  useEffect(() => {
    const closeRef = setInterval(() => {
      setCloseCounter((previous) => previous - 1);
    }, 1000);
    return () => {
      clearInterval(closeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      sx={{ p: 6 }}
      fullWidth
      maxWidth="md"
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle={t("Forgot to start break") + "?"}
        closeForm={handleClose}
        width="800px"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent sx={{ fontWeight: 200 }}>
        <Box sx={{ textAlign: "center", fontSize: "2.5rem", fontWeight: 500 }}>
          {t("Start break") + "?"}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "block", backgroundColor: "#BDBDBD" }}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <YesButton onClick={handleBreak} />
          <NoButton onClick={handleExit} />
        </Box>{" "}
        <Box sx={{ width: "100%", marginTop: "6px" }}>
          <LinearProgress
            variant="determinate"
            value={(closeCounter * 100) / displaySeconds}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StartBreakDialog);
