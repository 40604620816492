import React from "react";
import Button from "@mui/material/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const OKButton = (props) => {
  return (
    <Button
      style={{ width: "18rem", fontSize: "2rem" }}
      type="submit"
      variant="contained"
      startIcon={<CheckOutlinedIcon style={{ fontSize: "2rem" }} />}
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      OK
    </Button>
  );
};

export default OKButton;
