import React from "react";
import Button from "@mui/material/Button";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import { useTranslation } from "react-i18next";

const CancelButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      startIcon={<UnpublishedOutlinedIcon style={{ fontSize: "2rem" }} />}
      color="secondary"
      onClick={props.onClick}
      style={{
        width: "18rem",
        fontSize: "2rem",
        marginLeft: "4px",
      }}
    >
      {t("Cancel")}
    </Button>
  );
};

export default CancelButton;
