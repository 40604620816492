import React from "react";
import Button from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const CloseButton = (props) => {
  return (
    <Button
      title="Close"
      style={{
        width: "3rem",
        height: "4rem",
        position: "relative",
        borderRadius: "6px",
        margin: "0",
        color: props.color
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <CloseOutlinedIcon style={{ fontSize: "3rem" }} />
    </Button>
  );
};

export default CloseButton;
