import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { deDE } from "@mui/material/locale";

const white = "#FFFFFF";
const black = "#000000";
const somurablue = "#243573";

const mode = window.localStorage.getItem("theme") || "dark";

const theme = createTheme(
  {
    palette: {
      contrastThreshold: 4.5,
      mode: mode,
      action: {
        disabledBackground: colors.grey[300],
        disabled: "#F0F0F0",
      },
      primary: {
        // contrastText: white,
        dark: colors.indigo[800],
        main: somurablue,
        light: colors.indigo[100],
      },
      secondary: {
        contrastText: black,
        dark: mode === "dark" ? colors.lightBlue[900] : colors.orange[900],
        main: "#E0E0E0",
        light:
          mode === "dark" ? colors.lightBlue["A400"] : colors.orange["A400"],
      },
      somura: somurablue,
      red: { main: colors.red[500], contrastText: "#FFFFFF" },
      green: colors.green[800],
      orange: "#fb8c00",
      grey: {
        main: colors.grey[300],
        contrastText: "#F0F0F0",
      },
      lightgrey: colors.grey[200],
    },
    overrides: {
      MuiInputLabel: {
        // Name of the component ⚛️ / style sheet
        root: {
          // Name of the rule
          // color: "orange",
          "&$focused": {
            // increase the specificity for the pseudo class
            color: white,
          },
        },
      },
    },
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            color: "black",
            backgroundColor: "white",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            border: "1px solid black",
            borderRadius: "4px",
            color: "black",
            height: "4rem",
            width: "100%",
            fontSize: "2rem",
            "& > svg": {
              color: "black",
              fontSize: "4rem",
            },
            "&.MuiInputLabel-root": {
              color: "green",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "black",
            backgroundColor: "white",
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "#243573",
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            marginRight: "12px",
            backgroundColor: "lightgrey"
          },
          bar: {
            backgroundColor: "somura"
          }
        }
      }
    },
  },
  deDE
);

export default theme;
