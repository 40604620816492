import React from "react";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";


const ExitButton = (props) => {
  return (
    <Button
      title="Break"
      style={{
        width: "7rem",
        height: "7rem",
        position: "relative",
        borderRadius: "6px",
        color: props.disabled ? "#2F2F2F" : "white",
        backgroundColor: props.disabled ? "#2B0808" : "#e72b2d",
        margin: "6px 10px",
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <LogoutIcon style={{ fontSize: "5rem" }} />
    </Button>
  );
};

export default ExitButton;
