import React from "react";
import Button from "@mui/material/Button";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const StopTagButton = (props) => {
  return (
    <Button
      style={{
        width: "4rem",
        height: "4rem",
        fontSize: "3rem",
        borderRadius: "3rem",
        color: props.disabled ? "lightgrey" : "white",
        backgroundColor: props.disabled ? "grey" : "#243573",
        boxShadow: props.disabled
          ? "none"
          : "1px 1px 4px 1px rgba(0, 0, 0, 0.4)",
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <ClearOutlinedIcon style={{ fontSize: "3rem" }} />
    </Button>
  );
};

export default StopTagButton;
