import React from "react";
import Box from "@mui/material/Box";

const MenuButton = (props) => {
  return (
    <Box
      sx={{
        color: props.disabled ? "#BDBDBD" : props.color,
        backgroundColor: props.disabled ? "#E0E0E0" : props.backgroundColor,
        boxShadow: props.disabled ? "none" : "2px 2px 1px 0px rgba(0, 0, 0, 0.4)",
        height: "4rem",
        width: "100%",
        margin: "10px 0",
        borderRadius: "2rem",
        fontSize: "2.5rem",
        fontWeight: 400,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      onClick={props.onClick}
    >
      {props.text}
    </Box>
  );
};

export default MenuButton;
