import React from "react";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const CancelButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      startIcon={<ClearIcon style={{ fontSize: "2rem" }} />}
      color="secondary"
      onClick={props.onClick}
      style={{ width: "18rem", fontSize: "2rem", backgroundColor: "#E0E0E0" }}
    >
      {t("Cancel")}
    </Button>
  );
};

export default CancelButton;
