import React, { useEffect, useState } from "react";
import { store } from "../redux/store.js";
import { connect } from "react-redux";
import { fetchTimeClocks, setTimeClockActive } from "../redux/actions/time";
import { useTranslation } from "react-i18next";
import { getSetting } from "../components/Utilities";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import OKButton from "../components/OKButton";
import CancelButton from "../components/CancelButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const mapStateToProps = (state) => {
  return {
    timeClocks: state.time.TimeClocks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTimeClocks: (values) => dispatch(fetchTimeClocks(values)),
    setTimeClockActive: (values) => dispatch(setTimeClockActive(values)),
  };
};

const UnregisterDialog = ({
  timeClocks,
  fetchTimeClocks,
  setTimeClockActive,
  closeUnregisterDialog,
}) => {
  const { t } = useTranslation();
  const timeClockId = localStorage.getItem("terminalIdCode");
  const displaySeconds = parseInt(getSetting("adminDisplaySeconds"));
  const [closeCounter, setCloseCounter] = useState(displaySeconds);

  const handleClose = () => {
    store.dispatch({ type: "CLEAR_TIME_CLOCKS" });
    closeUnregisterDialog();
  };

  const handleSubmit = () => {
    if (timeClockId) {
      localStorage.removeItem("terminalIdCode");
      localStorage.removeItem("terminalName");
      setTimeClockActive({
        id: timeClocks?.find((TC) => TC.idCode === timeClockId)?.id,
        isActive: false,
      });
    }
    handleClose();
  };

  useEffect(() => {
    fetchTimeClocks({locationId: localStorage.getItem("location")});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (closeCounter < 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCounter]);

  useEffect(() => {
    const closeRef = setInterval(() => {
      setCloseCounter((previous) => previous - 1);
    }, 1000);
    return () => {
      clearInterval(closeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      sx={{ p: 6 }}
      fullWidth
      maxWidth="md"
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle={t("Unregister terminal")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent sx={{ fontWeight: 200 }}>
        <Box>
          <Box
            sx={{ textAlign: "center", fontSize: "2.5rem", fontWeight: 500 }}
          >
            {t("Tap on OK-button to unregister this terminal.")}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "block", backgroundColor: "#BDBDBD" }}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <CancelButton onClick={handleClose} />
          <OKButton onClick={handleSubmit} />
        </Box>{" "}
        <Box sx={{ width: "100%", marginTop: "6px" }}>
          <LinearProgress
            variant="determinate"
            value={(closeCounter * 100) / displaySeconds}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnregisterDialog);
